<template>
  <div>
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loading">
    </v-progress-linear>
    <div v-show="!loading">
      <v-tabs v-model="tab" hide-slider>
        <v-tab v-for="item in items" :key="item.tab" class="text-h6">
          {{ item.tab }}
        </v-tab>
      </v-tabs>

      <v-divider color="#512DA8" class="mb-3"></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <MainPersonalData />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <MainCompanyData />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <MainContactPersons />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <MainCars />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <MainDrivers />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import MainPersonalData from "@/components/app/MySpace/PersonalData/MainPersonalData.vue";
import MainCompanyData from "@/components/app/MySpace/CompanyData/MainCompanyData.vue";
import MainContactPersons from "@/components/app/MySpace/ContactPersons/MainContactPersons.vue";
import MainCars from "@/components/app/MySpace/Cars/MainCars.vue";
import MainDrivers from "@/components/app/MySpace/Drivers/MainDrivers.vue";
export default {
  name: "MySpace",
  data: () => ({
    // loading: true,
    tab: null,
    items: [
      { tab: "פרטים אישיים", content: "Tab 1 Content" },
      { tab: "פרטי החברה", content: "Tab 2 Content" },
      { tab: "אנשי קשר", content: "Tab 3 Content" },
      { tab: "רכבים", content: "Tab 4 Content" },
      { tab: "נהגים", content: "Tab 5 Content" },
    ],
  }),
  computed: {
    ...mapGetters(["loading"]),
  },
  mounted() {},
  methods: {},
  components: {
    MainPersonalData,
    MainCompanyData,
    MainContactPersons,
    MainCars,
    MainDrivers,
  },
  // components: {
  // },
};
</script>

<style scoped>
.v-tab--active {
  border: #1565C0;
  background-color: #1565C0;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  /* border-radius: 15px 15px 0px 0px; */
  color: white !important;
}
.v-divider {
  border-top-width: 1px;
}
</style>
