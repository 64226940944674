<template>
  <v-container fluid v-if="userInfo && !loading">
    <SubHeader
      :title="'פרטים אישיים'"
      :toClone="false"
      :toDelete="false"
      @edit="edit"
      @saveChanges="saveChanges"
      @cencel="cencel"
    />
    <v-text-field
      :label="'שם'"
      v-model="userInfoTMP.name"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-select
      :label="'תפקיד'"
      :loading="loading"
      :disabled="!editable"
      :items="userRoles || []"
      v-model="userInfoTMP.userRole"
    ></v-select>
    <v-text-field
      :label="'ת.ז.'"
      v-model="userInfoTMP.bussinesNumber"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-text-field
      :label="'מייל עסקי'"
      v-model="userInfoTMP.secondEmail"
      :disabled="!editable"
      :type="'mail'"
    ></v-text-field>
    <v-text-field
      :label="'טלפון'"
      v-model="userInfoTMP.phoneNumber"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <div>
      מספר אישי:
      <span class="text--disabled caption">{{ userInfoTMP.uid }}</span>
    </div>
    <div>
      תאריך הרשמה:
      <span class="text--disabled caption">{{
        userInfoTMP.createdAt | date("date")
      }}</span>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SubHeader from "@/components/app/common/Form/SubHeader";
export default {
  name: "Home",
  data: () => ({
    editable: false,
    userInfoTMP: {},
  }),
  methods: {
    async saveChanges() {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("updateUserInfo", this.userInfoTMP);
      await this.reloadUserInfo();
      this.editable = false;
    },
    async edit() {
      this.editable = true;
    },
    async cencel() {
      await this.reloadUserInfo();
      this.editable = false;
    },
    async reloadUserInfo() {
      await this.$store.dispatch("loading", true);
      let ui_tmp = await this.$store.dispatch("refreshUserInfo");
      this.userInfoTMP = Object.assign(this.userInfoTMP, ui_tmp);
      await this.$store.dispatch("loading", false);
    },
  },
  components: {
    SubHeader,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("getUserRoles");
    await this.$store.dispatch("getUserInfo");
    await this.reloadUserInfo();
    // this.name = this.userInfo.name
  },
  computed: {
    ...mapGetters(["loading", "userInfo", "userRoles"]),
  },
};
</script>