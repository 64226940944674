<template>
    <v-container flex>
      <v-row>
        <v-col xl="5" lg="5" md="12" sm="12" cols="12" >
          <CompanyData />
          <!-- <BankingData /> -->
        </v-col>
        <!-- <v-spacer></v-spacer> -->
        <v-col xl="6" lg="6" md="12" sm="12" cols="12">
          <OtherData />
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import CompanyData from "@/components/app/MySpace/CompanyData/components/CompanyData";
// import BankingData from "@/components/app/MySpace/CompanyData/components/BankingData.vue";
import OtherData from "@/components/app/MySpace/CompanyData/components/OtherData.vue";
export default {
  name: "Home",
  data: () => ({
  }),
  methods: {},
  components: {
    CompanyData,
    // BankingData,
    OtherData,
  },
  // components: {
  // },
};
</script>
