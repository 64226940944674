<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h6">התראות</div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-for="(n, i) in Notifications" :key="i" cols="12">
        <v-checkbox class="mb-n8" v-model="n.chacked" :label="n.title">
        </v-checkbox>
        <v-checkbox
          v-for="(o, io) in n.options"
          :key="io"
          class="mb-n8 mr-9"
          v-model="o.chacked"
          :label="o.title"
        >
        </v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    editable: false,
    Notifications: [
      {
        chacked: true,
        title: "הודעות על השלמת המכרז, סירוב, קבלת תלונה על ההזמנה",
        options: [
          { chacked: false, title: "מייל" },
          { chacked: true, title: "באתר" },
        ],
      },
      {
        chacked: true,
        title: "הודעות על הופעת הזמנות חדשות על פי המסנן שהגדרת",
        options: [
          { chacked: false, title: "מייל" },
          { chacked: true, title: "באתר" },
        ],
      },
      {
        chacked: true,
        title: "הודעות אחרות",
        options: [
          { chacked: false, title: "מייל" },
          { chacked: true, title: "באתר" },
        ],
      },
    ],
  }),
  methods: {
    saveChanges() {
      this.editable = false;
    },
  },
  // components: {
  // },
};
</script>