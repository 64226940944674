<template>
  <div>
    <v-dialog v-model="addDialog" persistent max-width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn outlined color="deep-orange darken-2" v-bind="attrs" v-on="on">
          {{ buttonLabel }}
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </template>
      <RModalForm
        :editable="editable"
        :fields="fields"
        :values="values"
        :id="id"
        :title="title"
        @Agree="Agree"
        @Cancel="Cancel"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RModalForm from "@/components/app/common/modals/RModalForm";

export default {
  props: {
    id: {},
    title: {},
    buttonLabel: {
      default: "add",
    },
    addingFunction: {
      required: true,
    },
    fieldsGetter: {
      required: true,
    },
    fieldsGetFunctionName: {
      required: true,
    },
  },
  data: () => ({
    addDialog: false,
    values: {},
    editable: true,
  }),
  methods: {
    async Agree() {
      this.addDialog = false;
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch(this.addingFunction, this.values);
      this.values = {};
      await this.$store.dispatch("loading", false);
    },
    Cancel() {
      this.addDialog = false;
      this.values = {};
    },
    getFieldsGetter() {
      return this.fieldsGetter;
    },
  },
  computed: {
    ...mapGetters(["loading"]),
    fields() {
      return this.$store.getters[this.fieldsGetter];
    },
  },
  async created() {
    await this.$store.dispatch(this.fieldsGetFunctionName);
  },
  components: {
    RModalForm,
  },
};
</script>