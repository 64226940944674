<template>
  <v-container fluid>
    <v-row>
      <v-col col-6>
        <div class="text-h6">אחר</div>
      </v-col>
      <v-col col-6>
        <div>
          <v-btn text v-if="!editable" small @click="editable = true"
            >לשנות</v-btn
          >
          <v-btn text v-if="editable" small @click="saveChanges">לשמור</v-btn>
        </div>
      </v-col>

      <!-- <v-spacer></v-spacer> -->
    </v-row>
    <v-file-input
    hide-input
      v-model="image"
      :rules="rules"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Pick an avatar"
      label="לוגו"
    >
      <template v-slot:append-outer>
        <v-avatar v-if="image" size="126">
          <img :src="url" alt="John" />
        </v-avatar>
      </template>
    </v-file-input>

    <v-row v-for="(column, i) in personalData" :key="i">
      <v-col>
        <v-text-field
          :label="column.title"
          :value="column.value"
          clearable
          :disabled="!editable || column.notEditable"
          :type="column.type"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    image: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    editable: false,
    personalData: [
      {
        title: "תיאור",
        value: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Volu",
        type: "text",
      },
      { title: "לוגו", value: "", type: "text" },
      { title: "", value: "6", type: "number", notEditable: true },
    ],
  }),
  methods: {
    saveChanges() {
      this.editable = false;
    },
  },
  watch: {
    image() {
      console.log(this.image);
    },
  },
  computed: {
    url() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    },
  },
  // components: {
  // },
};
</script>