<template>
  <div>
    <div v-for="(field, index) in fields" :key="index">
      <v-text-field
        v-if="field.type === 'text'"
        :label="field.label"
        v-model="values[field.value]"
        :disabled="!editable"
        :type="'text'"
      ></v-text-field>
      <v-text-field
        v-if="field.type === 'number'"
        :label="field.label"
        v-model="values[field.value]"
        :disabled="!editable"
        :type="'number'"
      ></v-text-field>
      <v-checkbox
        v-if="field.type === 'boolean'"
        :label="field.label"
        v-model="values[field.value]"
        :disabled="!editable"
      ></v-checkbox>
      <v-select
        v-if="field.type === 'select'"
        :label="field.label"
        :loading="loading"
        :disabled="!editable"
        :items="field.items || []"
        v-model="values[field.value]"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    editable: {
      default: true,
    },
    fields: {
      default: {},
    },
    values: {
      default: {},
    },
  },
  computed: {
    ...mapGetters(["loading"]),
  },
};
</script>

<style>
</style>