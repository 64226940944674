<template>
  <v-card rounded="lg">
    <v-card-title class="text-h5 deep-orange lighten-2 white--text">
      {{ title }}
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('Cancel')">
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <RForm
          :editable="editable"
          :fields="fields"
          :values="values"
        />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="green darken-1" text @click="$emit('Agree')">
        הוסף
      </v-btn>
      <v-btn color="red darken-1" text @click="$emit('Cancel')">
        ביטול
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
import RForm from "@/components/app/common/Form/RForm";

export default {
  props: {
    editable: {
      default: true,
    },
    fields: {
      default: {},
    },
    values: {
      default: {},
    },
    id: {
      default: 0,
    },
    title: {
      default: "title",
    },
  },
  components: {
    RForm,
  },
};
</script>

<style>
</style>