<template>
  <v-container flex>
    <v-row>
      <v-col col-6>
        <AddOneObjectModal
          :title="'נהג חדש'"
          :buttonLabel="'הוסף נהג חדש'"
          :addingFunction="'AddUserDriver'"
          :fieldsGetter="'userDriversFields'"
          :fieldsGetFunctionName="'userDriversFields'"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="5"
        lg="5"
        md="12"
        sm="12"
        cols="12"
        v-for="(person, key, index) in userDrivers"
        :key="`${key}`"
      >
        <ShowOneObject
          :id="key"
          :title="`נהג  ${index + 1}`"
          :fieldsGetter="'userDriversFields'"
          :fieldsGetFunctionName="'userDriversFields'"
          :getFieldValuesFunc="'userDriver'"
          :updateFieldValuesFunc="'updateUserDriver'"
          :removeFieldValuesFunc="'removeUserDriver'"
          :addFieldValuesFunc="'AddUserDriver'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import ShowOneObject from "@/components/app/MySpace/components/ShowOneObject";
import AddOneObjectModal from "@/components/app/MySpace/components/AddOneObjectModal";
export default {
  name: "MainDrivers",
  data: () => ({
    addDialog: false,
  }),
  methods: {},
  components: {
    ShowOneObject,
    AddOneObjectModal,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("getUserDrivers");
    await this.$store.dispatch("loading", false);
  },
  computed: {
    ...mapGetters(["loading", "userDrivers"]),
  },
};
</script>
