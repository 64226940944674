<template>
  <v-container fluid v-if="true && !loading">
    <SubHeader
      :title="'פרטי החברה'"
      :toClone="false"
      :toDelete="false"
      @edit="edit"
      @saveChanges="saveChanges"
      @cencel="cencel"
    />
    <v-text-field
      :label="'שם החברה'"
      v-model="companyInfoTMP.companyName"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-select
      :label="'סוג העסק'"
      :loading="loading"
      :disabled="!editable"
      :items="companyTypes || []"
      v-model="companyInfoTMP.companyType"
    ></v-select>
    <v-select
      :label="'סוג הפעילות'"
      :loading="loading"
      :disabled="!editable"
      :items="activityTypes || []"
      v-model="companyInfoTMP.activityType"
    ></v-select>
    <v-text-field
      :label="'ח.פ.'"
      v-model="companyInfoTMP.companyID"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-text-field
      :label="'תאריך רישום'"
      v-model="companyInfoTMP.registrationDate"
      :disabled="!editable"
      :type="'date'"
    ></v-text-field>
    <v-text-field
      :label="'מספר טלפון'"
      v-model="companyInfoTMP.phone"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-text-field
      :label="'אתר'"
      v-model="companyInfoTMP.webPage"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-text-field
      :label="'מייל'"
      v-model="companyInfoTMP.email"
      :disabled="!editable"
      :type="'email'"
    ></v-text-field>
    <v-text-field
      :label="'כתובת'"
      v-model="companyInfoTMP.phisicalAdress"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SubHeader from "@/components/app/common/Form/SubHeader";

export default {
  name: "Home",
  data: () => ({
    editable: false,
    // companyName: "",
    // companyType: "",
    // activityType: "",
    // companyID: "",
    // registrationDate: "",
    // phone: "",
    // webPage: "",
    // email: "",
    // phisicalAdress: "",
    companyInfoTMP: {},
  }),
  methods: {
    async saveChanges() {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch('updateCompanyInfo',this.companyInfoTMP)
      await this.reloadUserInfo();
      this.editable = false;
    },
    async edit() {
      this.editable = true;
    },
    async cencel() {
      await this.reloadUserInfo();
      this.editable = false;
    },
    async reloadUserInfo() {
      await this.$store.dispatch("loading", true);
      let ui_tmp = await this.$store.dispatch("refreshCompanyInfo");
      this.companyInfoTMP = Object.assign(this.companyInfoTMP, ui_tmp);
      await this.$store.dispatch("loading", false);
    },
  },
  components: {
    SubHeader,
  },
  computed: {
    ...mapGetters(["loading", "companyTypes", "activityTypes", "companyInfo"]),
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("getCompanyTypes");
    await this.$store.dispatch("getActivityTypes");
    await this.$store.dispatch("getCompanyInfo");
    await this.reloadUserInfo();
    // this.name = this.userInfo.name
  },
};
</script>