<template>
  <v-container fluid v-if="user && !loading">
    <SubHeader
      :title="'פרטי כניסה לאתר'"
      :toClone="false"
      :toDelete="false"
      @edit="edit"
      @saveChanges="saveChanges"
      @cencel="cencel"
    />
    <v-text-field
      :label="'מייל'"
      v-model="email"
      :disabled="!editable"
      :type="'email'"
    ></v-text-field>
    <v-text-field
      :label="'שנה סיסמה'"
      v-model="newPassword"
      :disabled="!editable"
      :type="'password'"
    ></v-text-field>
    <v-text-field
      :label="'הזן סיסמה שנית'"
      v-model="newPassword2"
      :disabled="!editable"
      :type="'password'"
    ></v-text-field>
  </v-container>
</template>

<script>
import SubHeader from "@/components/app/common/Form/SubHeader";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data: () => ({
    editable: false,
    email: "",
    newPassword: "",
    newPassword2: "",
    userRoles: [],
  }),
  methods: {
    async saveChanges() {
      let redirect = false;
      if (this.email !== this.user.email) {
        try {
          await this.$store.dispatch("updateUserAuthMail", this.email);
          redirect = true;
        } catch (error) {
          this.cencel();
          return;
        }
      }
      if (this.newPassword === this.newPassword2 && this.newPassword !== "") {
        try {
          await this.$store.dispatch("updateUserAuthPassword", this.newPassword);
          redirect = true;
        } catch (error) {
          this.cencel();
          return;
        }
      }
      if (redirect) {
        this.$store.dispatch("logOut");
        this.$router.push("/");
      } else {
        this.cencel();
      }
    },
    async edit() {
      this.editable = true;
    },
    cencel() {
      this.email = this.user.email;
      this.newPassword = "";
      this.newPassword2 = "";
      this.editable = false;
    },
  },
  components: {
    SubHeader,
  },

  async created() {
    this.email = this.user.email;
    // console.log(this.user);
  },
  computed: {
    ...mapGetters(["loading", "user", "userInfo"]),
  },
};
</script>