var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.title))])]),_c('v-col',[_c('div',{staticClass:"float-left"},[_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editable),expression:"!editable"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.editable),expression:"!editable"}],attrs:{"icon":"","text":"","color":"gray","small":""},on:{"click":_vm.edit}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("לשנות")])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable),expression:"editable"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editable),expression:"editable"}],attrs:{"icon":"","text":"","color":"green lighten-2","small":""},on:{"click":_vm.saveChanges}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v("לשמור")])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable && _vm.toDelete),expression:"editable && toDelete"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editable && _vm.toDelete),expression:"editable && toDelete"}],attrs:{"icon":"","text":"","color":"red lighten-2","small":""},on:{"click":_vm.delEl}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("למחוק")])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editable && _vm.toClone),expression:"!editable && toClone"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.editable && _vm.toClone),expression:"!editable && toClone"}],attrs:{"icon":"","text":"","color":"deep-orange lighten-2","small":""},on:{"click":_vm.clone}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}])},[_c('span',[_vm._v("לשכפל")])]),_c('v-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable),expression:"editable"}],attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editable),expression:"editable"}],attrs:{"icon":"","text":"","color":"gray","small":""},on:{"click":_vm.cencel}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("ביטול")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }